import React, { Component } from "react";
import "./App.css";
import {
  Navbar,
  Nav,
  Image,
  Container,
  Row,
  Col,
  Carousel,
} from "react-bootstrap";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Music from "./pages/music";
import Contact from "./pages/contact";
import Home from "./pages/home";
import Navigation from "./components/navigation";
import scrollToComponent from "react-scroll-to-component";
import TwitterLogo from "./img/twitter-3-xl.png";
import FacebookLogo from "./img/facebook-3-xl.png";
import InstagramLogo from "./img/instagram-3-xl.png";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      muted: true,
    };
  }

  scrollToSection = () => {
    scrollToComponent(this.Home, { offset: -20, align: "top", duration: 500 });
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (window.pageYOffset < 50) {
      this.setState({
        color: "navbar-empty",
      });
    }
    // Start fade
    else {
      this.setState({
        color: "navbar-fade",
      });
    }
  };

  render() {
    return (
      <div>
        <div className="landing text-center">
          <Navbar onClick={this.props.scroll} bg="transparent">
            <Nav className="mx-auto">
              <Nav.Link
                href="https://www.instagram.com/lewismonbarn/"
                target="_blank"
              >
                <Image width={50} src={InstagramLogo} />
              </Nav.Link>
              <Nav.Link
                href="https://www.facebook.com/lewismonbarn"
                target="_blank"
              >
                <Image width={50} src={FacebookLogo} />
              </Nav.Link>
              <Nav.Link
                href="https://www.twitter.com/lewismonbarn"
                target="_blank"
              >
                <Image width={50} src={TwitterLogo} />
              </Nav.Link>
            </Nav>
          </Navbar>
        </div>

        <Router>
          <section
            ref={(section) => {
              this.Home = section;
            }}
          >
            <Navigation
              color={this.state.color}
              scroll={this.scrollToSection}
            />
            <Route exact path="/" component={Home} />
            <Route path="/music" component={Music} />
            <Route path="/contact" component={Contact} />
          </section>
        </Router>

        <Carousel style={{ height: 400 }}>
          <Carousel.Item style={{ height: 400 }}>
            <img
              className="d-block w-100"
              style={{ minHeight: "100%" }}
              src="/table.jpg"
              alt="Third slide"
            />
          </Carousel.Item>
          <Carousel.Item style={{ height: 400 }}>
            <img
              className="d-block w-100"
              style={{ minHeight: "100%" }}
              src="/dance.jpg"
              alt="Third slide"
            />
          </Carousel.Item>
          <Carousel.Item style={{ height: 400 }}>
            <img
              className="d-block w-100"
              style={{ minHeight: "100%" }}
              src="/hoed.jpg"
              alt="First slide"
            />
          </Carousel.Item>
        </Carousel>

        <div className="footer">
          <Container className="text-center">
            <Row>
              <Col>
                <p className="quote">Nervous Records NYC</p>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default App;
