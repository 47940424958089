import React, { Component } from "react";
import { Container, Row, Col, Button, Form, Alert } from "react-bootstrap";
import Axios from "axios";

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      phone: "",
      message: "",
      isSent: false,
      errors: []
    };
  }

  handleInputChange = e => {
    const name = e.target.name;

    this.setState({
      [name]: e.target.value
    });
  };

  handleSubmit = event => {
    event.preventDefault();

    Axios({
      method: "post",
      url: "/mail.php",
      headers: { "content-type": "application/json" },
      data: this.state
    })
      .then(result => {
        this.setState({
          isSent: result.data.sent,
          errors: result.data.errors
        });
      })
      .catch(error => this.setState({ error: error.message }));
  };

  render() {
    let { errors } = this.state;

    return (
      <Container className="info-container" fluid>
        <Row>
          <Col sm={{ span: 6, offset: 3 }}>
            <h2 className="h2-margin text-center">Contact Lewis Monbarn</h2>

            <Form className="text-white">
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  name="name"
                  type="text"
                  placeholder="Lewis Monbarn"
                  onChange={this.handleInputChange}
                />
              </Form.Group>

              <Row>
                <Col md={6}>
                  <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      name="email"
                      className="email"
                      placeholder="email@gmail.com"
                      onChange={this.handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="phone">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      name="phone"
                      className="text"
                      placeholder="+31"
                      onChange={this.handleInputChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group controlId="formBasicPassword">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  name="message"
                  className="textarea-color"
                  as="textarea"
                  placeholder="text"
                  onChange={this.handleInputChange}
                />
              </Form.Group>

              <Button className="custom-btn" onClick={this.handleSubmit}>
                Send
              </Button>
            </Form>

            <br />
            {this.state.isSent && (
              <Alert className="form-error">Thank you for contacting me.</Alert>
            )}
            {errors.map((error, idx) => (
              <Alert key={idx} className="form-error">
                {error}
              </Alert>
            ))}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Contact;
