import React, { Component } from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";

class Home extends Component {
  render() {
    return (
      <Container className="info-container" fluid>
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <h2 className="text-center h2-margin">Lewis Monbarn</h2>
            <p>
              Lodewyck started making electronic music at the age of 12 for his
              school musical. In the vinyl days his brother taught him to play
              trance and hard techno. Years later they would perform under the
              name Ruttenbergs and release music on Parquet, Traum and Manual
              just to name a few. At the same time Lodewyck started Valrave and
              In the Cloud Festival. This resulted in more than a 100+ organized
              parties over a span of five years. After this period came a time
              of development, Lodewyck moved to Ibiza and there he decided it
              was time to fully focus on producing and performing as Lewis
              Monbarn. Since the first release every track has hit the charts
              and with releases on Nervous Records, Datagroove and his own new
              label Massaïa the flood of new music is not stopping any time
              soon.
            </p>

            <h2 className="text-center h2-margin">Newest releases</h2>

            <iframe
              title="soundSloud"
              width="100%"
              height="450"
              scrolling="no"
              frameborder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/users/521142138&color=%23ff0000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=true&show_teaser=true"
            ></iframe>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Home;
