import React, { Component } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

class Music extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef(); // Create a ref object
  }
  render() {
    return (
      <Container ref={this.myRef} className="info-container" fluid>
        <Row className="text-center">
          <Col sm={{ span: 6, offset: 3 }}>
            <h2 className="text-center h2-margin">Music</h2>
            <iframe
              title="soundSloud"
              width="100%"
              height="450"
              scrolling="no"
              frameborder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/users/521142138&color=%23ff0000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=true&show_teaser=true"
            ></iframe>

            <h2 className="text-center h2-margin">More</h2>

            <Row>
              <Col>
                <Button
                  className="custom-btn"
                  href="https://open.spotify.com/artist/12ajTqVRGzZn1TVF1sTOEM"
                  target="_blank"
                  variant="dark"
                  size="lg"
                  block
                >
                  Spotify
                </Button>
                <Button
                  className="custom-btn"
                  href="https://www.beatport.com/artist/lewis-monbarn/751509"
                  target="_blank"
                  variant="dark"
                  size="lg"
                  block
                >
                  Beatport
                </Button>
              </Col>
              <Col>
                <Button
                  className="custom-btn"
                  href="https://www.traxsource.com/artist/484052/lewis-monbarn"
                  target="_blank"
                  variant="dark"
                  size="lg"
                  block
                >
                  Traxsource
                </Button>
                <Button
                  className="custom-btn"
                  href="https://itunes.apple.com/ai/album/pasta-bolo-ep-edits/1454835094?uo=4&app=itunes"
                  target="_blank"
                  variant="dark"
                  size="lg"
                  block
                >
                  Itunes
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Music;
