import React, { Component } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Navbar, Nav, Image } from "react-bootstrap";
import Brand from "../brand.png";

class Navigation extends Component {
  render() {
    return (
      <Navbar
        onClick={this.props.scroll}
        className={this.props.color}
        expand="md"
        fixed="bottom"
      >
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
            <LinkContainer to="/music">
              <Nav.Link className="text-light">
                <b className="text-light">Music</b>
              </Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>

        <LinkContainer to="/">
          <Navbar.Brand className="text-light mr-auto d-none d-md-block">
            <Image
              alt=""
              src={Brand}
              width="300"
              className="d-inline-block align-top"
              fluid
            />
          </Navbar.Brand>
        </LinkContainer>

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
            <LinkContainer to="/contact">
              <Nav.Link>
                <b className="text-light">Contact</b>
              </Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default Navigation;
